@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap');
body {
  background-color: #ffffff;
}

body.dark {
  background-color: #111111;
}
.Back-ground_color {
  background-color: #ffffff;
}


.home {
  height: 80%;
  width: 100%;
  background-color: rgb(180, 154, 102);
}

.cover-color {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
}

.name-logo-style {
  width: 40%;
  height: 10%;
}

.home-android-ios {
  cursor: pointer;
  padding-top: 1%;
  justify-content: center;
  display: flex;

}

.top-link-title {
  text-align: right;
  padding-top: 2%;
  margin-right: 4%;
}

.Home-logo {
  text-align: center;
  font-family: 'Verdana', 'Lucida Sans Regular', sans-serif;
  color: #fff;
  font-size: 100;
}

.Home-logo-style {
  padding-top: 5%;
  justify-content: center;
  display: flex;
}

.Home-logo-image {
  width: 280;
  height: 100;
}

.download-now-button {
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.main-pargrah-font{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: #1c2b33;
}

.pargrah-font{
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  color: #1c2b33;
}

.pargrah-font-Download{
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #2049ff;
}

.home-title {
  padding-top: 42px;
  text-align: center;
}

.home-title-web {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-size: 250%
}

.home-sub-title-web {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-size: 180%
}

.download-now-web{
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-size: 120%
}

.home-title-mobile{
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-size: 200%;
  line-height: 10px;
}

.download-now-mobile{
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-size: 130%
}

.home-text1 {
  text-align: center;
}

.home-android-ios {
  cursor: pointer;
  padding-top: 1%;
  justify-content: center;
  display: flex;

}

.home-div {
  margin-top: 1%;
}

.mobile-logo {
  margin-top: 35%;
  justify-content: center;
  display: flex;
}

.mobile-logo-name {
  justify-content: center;
  display: flex;
}

.mockup-image {
  width: 70%;
  height: 70%;
}

.middle-screen {
  margin-top: 100px;
}

.middle-title {
  color: #111;
  margin-left: 10%;
  font-size: 40
}

.middle-pargraph {
  color: #111;
  width: 40%;
  margin-left: 10%;
  font-size: 30
}

.up-screen {
  height: 36px;
  width: 36px;
  box-shadow: 3px 3px 25px rgb(184, 184, 184);
  background-color: rgb(255, 255, 255);
  border-radius: 18px;
}

.footer-color {
  margin-top: 1%;
}

.social-icons {
  padding-top: 1%;
  display: flex;
  justify-content: center;
}

.footer {
  padding-top: 10px;
  text-align: center;
}

.home-android-ios-footer {
  padding-top: 10px;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}

.copy-right {
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.logo {
  font-size: 20px;
  font-family: 'Lobster', cursive;
  margin-top: 1%;
}

.home-logo {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #1c2b33;
}

.blog-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.blog-Name {
  display: flex;
  justify-content: center;
}

.div-container {
  box-sizing: border-box;
  max-width: 895px;
  margin: 0 auto;
  padding: 4px;

}

.div-contain {
  box-shadow: 0px 0px 6px rgb(184, 184, 184);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
}

.blog-title-style {
  font-size: 16px;
  margin-top: 6%;
  margin-left: 15px;
  margin-right: 15px;
}

.blog-text-style {
  font-size: 14px;
  color: rgb(44, 44, 44);
  line-height: inherit;
  font-family: 'Beausite Classic', Helvetica, Arial, sans-serif;
}

.blog-text-style-block {
  width: 90%;
  height: 30%;
  margin-left: 15px;
}

.write-text-style {
  margin-left: 15px;
}

.blog-div-container {
  display: flex;
  justify-content: center;
}

.blog-content {
  width: 60%;
  margin-bottom: 50px;
}

.blog-tip-title {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(0, 0, 0);
  text-align: center;
}

.blog-pargraph-title {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(0, 0, 0);
  margin-top: 5%;
}

.blog-pargraph-date {
  font-size: 15px;
  font-family: "Courier New", Courier, monospace;
  color: rgb(0, 0, 0);
}

.blog-pargraph-sub-title {
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-family: "Courier New", Courier, monospace;
}

.blog-pargraph {
  font-size: 18px;
  color: rgb(0, 0, 0);
  line-height: 1.6;
  font-family: "Courier New", Courier, monospace;
}

.safty-pargraph-title {
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(0, 0, 0);
}


.safty-pargraph-title1 {
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(0, 0, 0);
}

.video-algin {
  display: flex;
  justify-content: center;
}

.center-about-icon {
  text-align: center;
}

.about-icon {
  width: 50px;
  height: 50px;
}

.about-contain {
  margin: 10px;
}

.about-title-style {
  width: 300px;
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  text-align: center;
}

.about-text-style {
  width: 300px;
  font-size: 16px;
  color: rgb(0, 0, 0);
  padding: 10px;
  text-align: center;
  padding-bottom: 50%;
}


.mission-title {
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-family: 'Lobster', cursive;
  margin-top: 5%;
}

.about-pargraph {
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-family: 'Lobster', cursive;
}

.about-pargraph1 {
  margin-top: 5%;
}

.about-style {
  width: 60%;
  margin-left: 20%;
  display: flex;
  justify-content: center;
}

.about-image {
  position: absolute;
  top: 40%;
  right: 25%;
}

.contact-us-topic {
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(0, 0, 0);
  margin-top: 12px;
}

.contact-us-title {
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(0, 0, 0);
  margin-top: 8%;
  margin-left: 15%;
}

.contact-con {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.contact-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.contact-contain {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.submit-btn {
  float: right;
  padding: 7px 25px;
  border-radius: 60px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn-null {
  float: right;
  padding: 7px 25px;
  border-radius: 60px;
  display: inline-block;
  background-color: #727477;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.div-image {
  position: absolute;
  top: 50px;
  z-index: -1;
}

.blog-image {
  width: 100%;
  height: 10%;
}

.flexDirect {
  display: flex;
  justify-content: center;
}

.phone-up-image {
  width: 100%;
  height: 130%;
}

.phone-up-image-mobile {
  width: 100%;
  height: 100%;
}

.drawer-icon {
  width: 18px;
  height: 18px;
}

.blog-pargraph-title1 {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
}

.blog-title-header {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
}

.blog-pargraph-autor {
  font-size: 14px;
  color: rgb(0, 0, 0);
}

.headerStyle {
  border-bottom: 5px solid green;
  ;
}

.home-content {
  width: 50%;
}

.home-page {
  margin: 0px;
  background-color: greenyellow;
  width: 100%;
  height: 100%;
}


.not-found-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.not-found-inline {
  border-left: 1px solid #fff;
}

.home-drawer {
  display: none;
}

.main-logo {
  display: flex;
}

.center-text {
  text-align: center;
  background-color: #111;
  width: 100%;
  height: 50px;
  margin-bottom: 25px;
}

.blog-image-cover {
  width: 280px;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  position: relative;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 6px;
  border: 4px solid #000;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {

  .home-drawer {
    display: flex;
  }

  .top-link-title {
    display: none;
  }

  .main-logo {
    display: none;
  }

  .name-logo-style {
    width: 98%;
    height: 20%;
  }

  .Home-logo-style {
    margin-top: 35%;
    justify-content: center;
    display: flex;
  }

  .home-title {
    padding-top: 20%;
    text-align: center;
  }

  .home-text1 {
    margin-top: 15%;
    text-align: center;
  }

  .home-mockup {
    position: absolute;
    top: 140%;
    left: 15%;
  }

  .mockup-image {
    width: 70%;
    height: 70%;
  }

  .middle-title {
    color: rgb(0, 0, 0);
    margin-left: 10%;
    padding-top: 23%;
    font-size: 30
  }

  .middle-pargraph {
    color: rgb(0, 0, 0);
    width: 250px;
    margin-left: 10%;
    font-size: 30
  }

  .download-now-button {
    margin-top: 20%;
    cursor: pointer;
    justify-content: center;
    display: flex;
  }

  .footer {
    width: 100%;
    padding-top: 10px;
    text-align: none;
  }

  .footer-color {
    margin-top: 2%;
  }

  .blog-container {
    margin-top: 20%;
  }

  .blog-content {
    width: 90%;
  }

  .blog-title-header {
    color: rgb(0, 0, 0);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }

  .contact-us-title {
    font-size: 18px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(0, 0, 0);
    margin-top: 23%;
    margin-left: 10%;
  }

  .contact-container {
    margin-left: 5%;
    margin-top: 5%
  }

  .contact-contain {
    width: 82%;
  }

  .contact-us-topic {
    font-size: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(0, 0, 0);
    margin-top: 12px;
  }

  .submit-btn {
    float: none;
    padding: 7px 25px;
    border-radius: 60px;
    display: inline-block;
    background-color: #4b8cfb;
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
      0 2px 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .submit-btn-null {
    float: none;
    padding: 7px 25px;
    border-radius: 60px;
    display: inline-block;
    background-color: #727477;
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
      0 2px 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .mission-title {
    font-size: 18px;
    color: rgb(0, 0, 0);
    font-family: 'Lobster', cursive;
    margin-top: 5%;
  }

  .about-pargraph {
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-family: 'Lobster', cursive;
  }

  .about-pargraph1 {
    margin-top: 5%;
  }

  .about-style {
    width: 95%;
    margin-left: 4%;
    margin-top: 3%;
  }

  .about-image {
    display: none;
  }

  .blog-pargraph-title {
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(0, 0, 0);
    margin-top: 4%;
  }

  .div-contain {
    box-shadow: 0px 0px 6px rgb(184, 184, 184);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: block;
    justify-content: center;
    margin-bottom: 30px;
    width: 95%;
    height: 100%;
  }

  .blog-image-cover {
    width: 100%;
    height: 10%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }

  .blog-title-style {
    color: rgb(0, 0, 0);
    font-size: 16px;
    margin-top: 5%;
    margin-left: 15px;
    margin-right: 15px;
  }
}